import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModTwoColsAnim from "../../../components/Chronology/Modules/ModTwoColsAnim";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";

export const frontmatter = {
  title: "Día -01",
  week: "Semana 0",
  day: "12",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-12",
  path: "/cronologia/semana-0#dia-12-mar",
};
const Day12M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Turquía declara su primer caso confirmado.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong> se han registrado 822 más que el día
          anterior, y 84 fallecidos más,lo que representa un icremento del 78.7%
          intradía. El total de personas en UCI asciende a 190.
        </ModText>
        <ModImage
          src="/images/svg/12_mar_3_img_juntas.svg"
          alt="medidas excepcionales para mitigar el impacto del coronavirus"
        />
        <ModText>
          El <strong>Consejo de Ministros</strong> ha aprobado un{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/120320-enlace-plancovid.aspx">
            Real Decreto-ley
          </InlineLink>{" "}
          con medidas excepcionales para mitigar el impacto del coronavirus
          COVID-19 en todo el país. Las medidas tienen como objetivo reformar el
          sistema sanitario, facilitar la protección de los menores en situación
          de vulnerabilidad y asegurar el correcto funcionamiento del sistema
          educativo, entre otras.
        </ModText>
        <ModTwoCols
          src="/images/svg/12_mar_teletrabajo.svg"
          alt="trabajo en casa"
          small={true}
          inverted={false}
        >
          Los{" "}
          <strong>
            empleados públicos de la Administración General del Estado
          </strong>{" "}
          podrán acogerse a modalidades no presenciales de trabajo y se
          flexibilizará la jornada laboral de quienes tengan hijos menores a su
          cargo.
        </ModTwoCols>
        <ModTwoColsAnim
          src="/images/anim/12_mar_educacion_distancia.svg"
          alt="educación a distancia"
          inverted
        >
          En coordinación con las comunidades autónomas, y para paliar los
          efectos del cierre de los centros docentes, se ajustará el límite
          mínimo de los días lectivos, se adaptarán los calendarios de acceso y
          matrícula universitarios y de formación profesional, y se dispondrán
          los medios para facilitar la <strong>educación a distancia</strong>.
        </ModTwoColsAnim>
        <ModTwoColsAnim src="/images/anim/12_mar_renfe.svg" alt="Renfe">
          <strong>Renfe</strong> facilitará cambios y anulaciones de billetes,
          sin coste alguno, a partir de hoy, y devolverá el importe íntegro de
          los billetes de tren a todos los viajeros de los programas de
          vacaciones del Imserso.
        </ModTwoColsAnim>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/120320-enlace-plancovid.aspx"
            name="Real Decreto con medidas excepcionales"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day12M;
